import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
import SymbolSvg from "../assets/images/site-branding/symbol.inline.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimateIntroBanner id="banner" className="background_logo light" background={<><RowLines mdxType="RowLines" /><SymbolSvg mdxType="SymbolSvg" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Privacy Policy
    </Heading>
  </Block>
  <Block className="content_block" mdxType="Block">
    <Paragraph mdxType="Paragraph">Welcome to hausman.com This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully.</Paragraph>
  </Block>  
    </AnimateIntroBanner>
    <Row id="all_terms" background={<RowLines disableScrollIndicator={true} mdxType="RowLines" />} mdxType="Row">
  <Block className="list_block" mdxType="Block">
  <div className="item">
  <span className="number">1</span>
  <Heading level={3} mdxType="Heading">Description of website</Heading>
  <Paragraph mdxType="Paragraph">Hausman  aspires to create products that primarily serve the disability community. Our first range of products, the Attachment System, Cupholder, Essentials Plus Bag and Essentials Bag (the “Products”) are primarily designed for the use on manual wheelchairs and functional, attractive, and hopefully inspirational. You are welcome to browse the Website as a visitor without providing any information to us. Registered users (“Registered Users”) of our Website can (i) view all publicly-accessible content, (ii) email us, (iii) post anonymous comments, (iv) place Product orders, on an individual or group basis, (v) sign-up for alerts and other notifications, and (vi) sign-up for promotions. If you would like to use our Website as a Registered User, you will need to create a user account. During the registration process, you will have to provide your name, email address, shipping address and create a user name and password for your account. You represent and warrant that all registration information you submit is truthful and accurate and you will maintain the accuracy of such information. You are solely responsible for the confidentiality of your Registered User account, as well as for its use and misuse. You will promptly inform us of any need to deactivate a user name or password. We reserve the right to delete or change your user name and/or password at any time and for any reason. We are under no obligation to accept you as a Registered User, and may accept or reject any registration, in our sole and absolute discretion.</Paragraph>
  </div>
  <div className="item">
  <span className="number">2</span>
  <Heading level={3} mdxType="Heading">Your agreement</Heading>
  <Paragraph mdxType="Paragraph">As a multidisciplinary designer with Rokt’s marketing department, you will an integral part of our marketing team. You will work alongside our senior designer to design and execute a variety of creative campaigns spanning sales and marketing materials, both in print and digital spaces, as well as video + motion graphics, and social content.  </Paragraph>
  </div>  
  <div className="item">
  <span className="number">3</span>
  <Heading level={3} mdxType="Heading">Provision of services</Heading>
  <Paragraph mdxType="Paragraph">Considering that the use of Services is not subject to any type of payment, the User accepts the following: </Paragraph>
  <ol className="stagger_list">
  <li>Hausman. may at any time suspend or end the provision of Services, entirely or in part;</li>
  <li>Use of Services is at the User's own risk and responsibility;</li>
  <li>Services provided as viewed on the web site are free; Hausman. does not guarantee validity, accuracy or continuity of data;</li>
  <li>Hausman. does not guarantee that Services will fulfil User requirements, that they shall be uninterrupted, convenient, secure or error-free;</li>
  <li>Hausman. does not guarantee to fulfil User expectations of the Services;</li>
  <li>Services provided by Saipem do not include those related to telecommunications (including data transmission over the Internet); the acquisition of said telecommunication services is to be made autonomously by the User;</li>
  <li>Services may not be supported by certain commercially available browsers and/or operating systems or versions thereof. It is the User's responsibility to identify the correct combination of software and hardware to gain adequate access to the Services.</li>
  </ol>
  </div>    
  <div className="item">
  <span className="number">4</span>
  <Heading level={3} mdxType="Heading">General conditions of use for services relating to financial instruments</Heading>
  <Paragraph mdxType="Paragraph">Despite all efforts made by Saipem, data provided on this web site may not be complete at all times, accurate, or up-to-date. Hausman. does not undertake any obligation nor accept liability arising from inaccuracies or omissions on its website, other than fraudulent misrepresentation. Decisions taken on the basis of information contained on this web site are the sole responsibility of the individual. No information contained on this web site constitutes or can be deemed to constitute an invitation or inducement to invest or otherwise deal in Hausman.'s securities.</Paragraph>
  <Paragraph mdxType="Paragraph">Hausman. is subject to periodic reporting obligation under Italian Law. Hausman. recommends that any decisions on investments in Hausman.'s securities be taken subsequent to a review of documents filed with or provided to the relevant regulatory authorities. Neither Hausman. nor any of its subsidiaries can be held liable for contents of any other website, including those that have links to their websites. Hausman does not accept liability in connection with such sites or links. Saipem's website, and documents featured on it, contain forward-looking statements pertaining to future events and results that are based on current expectations, estimates, forecasts, and projections for the industry in which Saipem operates and the beliefs and assumptions of Saipem's management.</Paragraph>
  <Paragraph mdxType="Paragraph">In particular, statements associated with management objectives, income expectations, margins, costs, return on equity, risk management and competition are forward-looking in nature. Terms such as "expects', "anticipates", "targets", "projects", "intends", "plans", "believes", "seeks", "estimates", and similar words and expressions are intended as forward-looking statements.</Paragraph>
  <Paragraph mdxType="Paragraph">These are only predictions and are subject to risks, uncertainties, and assumptions that are difficult to predict as they relate to future events and circumstances. Saipem's actual results may therefore differ materially from those expressed or implied in forward-looking statements. Forward-looking statements made by or on behalf of Saipem refer only to the time at which they are made. Saipem is not obliged to update forward-looking statements to reflect changes in its expectations resulting from changes in events, conditions or circumstances on which any such statement is based.</Paragraph>
  </div>
  </Block>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      